import React from "react";
import Vidazoo from "../vendor/Vidazoo";

const VideoAd = () => {
  return (
    <div className="adunit adunit--video">
      <div className="adunit__container">
        <Vidazoo vidazooId="65647afdb23cea2bf1b58016" />
      </div>
    </div>
  );
};

export default VideoAd;
